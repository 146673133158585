.service_table tbody tr {
    @apply border-b border-gray-200 min-h-[64px] h-[64px];
}
/* .service_table tbody tr:last-child {
    @apply border-none;
} */

.service_table tbody .p-datatable-row-expansion > td {
    @apply !p-0;
}

.service_table tbody .p-datatable-row-expansion td .p-datatable-tbody tr {
    @apply !bg-gray-50
}
.datatable-custom-service.service_table .p-datatable .p-datatable-tbody > tr > td:last-child {
    width: 70px !important;
    text-align: center;
}
.datatable-custom-service .p-datatable-wrapper
{
    overflow: visible !important;
}

.sales-table .datatable-custom-service .p-datatable-wrapper
{
    overflow: auto !important;
}
.table-arrow-btn .p-row-toggler
{
    @apply border border-gray-200 bg-gray-50 h-[25px] w-[25px] p-[6px] shadow-sm flex justify-center items-center rounded-lg;
}
.table-arrow-btn
{
    justify-content: end;
}
.close-page-btn
{
    @apply bg-gray-200 rounded-full z-50 xl:p-2 sm:p-1.5 p-1 cursor-pointer flex justify-center items-center transition-all duration-[0.3s] hover:bg-gray-300;
}
.logo_input_width
{
    @apply max-w-[232px] w-[232px];
}