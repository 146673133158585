/*
 * Table styles
 */
/*
 * Control feature layout
 */
:root {
	--dt-row-selected: 13, 110, 253;
	--dt-row-selected-text: 255, 255, 255;
	--dt-row-selected-link: 9, 10, 11;
	--dt-row-stripe: 0, 0, 0;
	--dt-row-hover: 0, 0, 0;
	--dt-column-ordering: 0, 0, 0;
	--dt-html-background: white;
}
:root.dark {
	--dt-html-background: rgb(33, 37, 41);
}
table.dataTable {
	td.dt-control {
		text-align: center;
		cursor: pointer;
		&:before {
			display: inline-block;
			color: rgba(0, 0, 0, 0.5);
			content: "▶";
		}
	}
	tr.dt-hasChild {
		td.dt-control {
			&:before {
				content: "▼";
			}
		}
	}
	thead {
		>tr {
			>th.sorting {
				cursor: pointer;
				position: relative;
				padding-right: 26px;
				white-space: nowrap;
				&:before {
					position: absolute;
					display: block;
					opacity: 0.5;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					bottom: 50%;
					width: 8px;
					height: 8px;
					content: '';
					background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy45OTk5NiAyQzQuMTc2NzYgMiA0LjM0NjM2IDIuMDcwMjQgNC40NzEzNiAyLjE5NTI2TDcuMTM4MDMgNC44NjE5M0M3LjM5ODM2IDUuMTIyMjggNy4zOTgzNiA1LjU0NDM5IDcuMTM4MDMgNS44MDQ3NEM2Ljg3NzY5IDYuMDY1MDcgNi40NTU1NiA2LjA2NTA3IDYuMTk1MjMgNS44MDQ3NEwzLjk5OTk2IDMuNjA5NDdMMS44MDQ3IDUuODA0NzRDMS41NDQzNSA2LjA2NTA3IDEuMTIyMjQgNi4wNjUwNyAwLjg2MTg4NiA1LjgwNDc0QzAuNjAxNTM5IDUuNTQ0MzkgMC42MDE1MzkgNS4xMjIyOCAwLjg2MTg4NiA0Ljg2MTkzTDMuNTI4NTYgMi4xOTUyNkMzLjY1MzU2IDIuMDcwMjQgMy44MjMxNiAyIDMuOTk5OTYgMloiIGZpbGw9IiM4QzlDQkIiLz4KPC9zdmc+Cg==");
					background-repeat: no-repeat;
					background-position: center center;
					// content: "▲";
					// content: "▲"/"";
				}
				&:after {
					position: absolute;
					display: block;
					opacity: 0.5;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					top: 50%;
					width: 8px;
					height: 8px;
					content: '';
					background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgOSA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMi4zMDQ3NSAyLjY5NTI3QzIuMDQ0NCAyLjQzNDkzIDEuNjIyMjkgMi40MzQ5MyAxLjM2MTkzIDIuNjk1MjdDMS4xMDE1OSAyLjk1NTYgMS4xMDE1OSAzLjM3NzczIDEuMzYxOTMgMy42MzgwNkw0LjAyODYxIDYuMzA0NzNDNC4yODg5NCA2LjU2NTA3IDQuNzExMDcgNi41NjUwNyA0Ljk3MTQxIDYuMzA0NzNMNy42MzgwOCAzLjYzODA2QzcuODk4NDEgMy4zNzc3MyA3Ljg5ODQxIDIuOTU1NiA3LjYzODA4IDIuNjk1MjdDNy4zNzc3NCAyLjQzNDkzIDYuOTU1NjEgMi40MzQ5MyA2LjY5NTI4IDIuNjk1MjdMNC41MDAwMSA0Ljg5MDUzTDIuMzA0NzUgMi42OTUyN1oiIGZpbGw9IiM4QzlDQkIiLz4KPC9zdmc+Cg==");
					background-repeat: no-repeat;
					background-position: center center;
					// content: "▼";
					// content: "▼"/"";
				}
			}
			>th.sorting_asc {
				cursor: pointer;
				position: relative;
				padding-right: 26px;
				&:before {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					bottom: 50%;
					width: 8px;
					height: 8px;
					content: '';
					background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy45OTk5NiAyQzQuMTc2NzYgMiA0LjM0NjM2IDIuMDcwMjQgNC40NzEzNiAyLjE5NTI2TDcuMTM4MDMgNC44NjE5M0M3LjM5ODM2IDUuMTIyMjggNy4zOTgzNiA1LjU0NDM5IDcuMTM4MDMgNS44MDQ3NEM2Ljg3NzY5IDYuMDY1MDcgNi40NTU1NiA2LjA2NTA3IDYuMTk1MjMgNS44MDQ3NEwzLjk5OTk2IDMuNjA5NDdMMS44MDQ3IDUuODA0NzRDMS41NDQzNSA2LjA2NTA3IDEuMTIyMjQgNi4wNjUwNyAwLjg2MTg4NiA1LjgwNDc0QzAuNjAxNTM5IDUuNTQ0MzkgMC42MDE1MzkgNS4xMjIyOCAwLjg2MTg4NiA0Ljg2MTkzTDMuNTI4NTYgMi4xOTUyNkMzLjY1MzU2IDIuMDcwMjQgMy44MjMxNiAyIDMuOTk5OTYgMloiIGZpbGw9IiM4QzlDQkIiLz4KPC9zdmc+Cg==");
					background-repeat: no-repeat;
					background-position: center center;
					// content: "▲";
					// content: "▲"/"";
					opacity: 1;
				}
				&:after {
					position: absolute;
					display: block;
					opacity: 0.5;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					top: 50%;
					width: 8px;
					height: 8px;
					content: '';
					background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgOSA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMi4zMDQ3NSAyLjY5NTI3QzIuMDQ0NCAyLjQzNDkzIDEuNjIyMjkgMi40MzQ5MyAxLjM2MTkzIDIuNjk1MjdDMS4xMDE1OSAyLjk1NTYgMS4xMDE1OSAzLjM3NzczIDEuMzYxOTMgMy42MzgwNkw0LjAyODYxIDYuMzA0NzNDNC4yODg5NCA2LjU2NTA3IDQuNzExMDcgNi41NjUwNyA0Ljk3MTQxIDYuMzA0NzNMNy42MzgwOCAzLjYzODA2QzcuODk4NDEgMy4zNzc3MyA3Ljg5ODQxIDIuOTU1NiA3LjYzODA4IDIuNjk1MjdDNy4zNzc3NCAyLjQzNDkzIDYuOTU1NjEgMi40MzQ5MyA2LjY5NTI4IDIuNjk1MjdMNC41MDAwMSA0Ljg5MDUzTDIuMzA0NzUgMi42OTUyN1oiIGZpbGw9IiM4QzlDQkIiLz4KPC9zdmc+Cg==");
					background-repeat: no-repeat;
					background-position: center center;
					// content: "▼";
					// content: "▼"/"";
				}
			}
			>th.sorting_desc {
				cursor: pointer;
				position: relative;
				padding-right: 26px;
				&:before {
					position: absolute;
					display: block;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					bottom: 50%;
					width: 8px;
					height: 8px;
					content: '';
					background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy45OTk5NiAyQzQuMTc2NzYgMiA0LjM0NjM2IDIuMDcwMjQgNC40NzEzNiAyLjE5NTI2TDcuMTM4MDMgNC44NjE5M0M3LjM5ODM2IDUuMTIyMjggNy4zOTgzNiA1LjU0NDM5IDcuMTM4MDMgNS44MDQ3NEM2Ljg3NzY5IDYuMDY1MDcgNi40NTU1NiA2LjA2NTA3IDYuMTk1MjMgNS44MDQ3NEwzLjk5OTk2IDMuNjA5NDdMMS44MDQ3IDUuODA0NzRDMS41NDQzNSA2LjA2NTA3IDEuMTIyMjQgNi4wNjUwNyAwLjg2MTg4NiA1LjgwNDc0QzAuNjAxNTM5IDUuNTQ0MzkgMC42MDE1MzkgNS4xMjIyOCAwLjg2MTg4NiA0Ljg2MTkzTDMuNTI4NTYgMi4xOTUyNkMzLjY1MzU2IDIuMDcwMjQgMy44MjMxNiAyIDMuOTk5OTYgMloiIGZpbGw9IiM4QzlDQkIiLz4KPC9zdmc+Cg==");
					background-repeat: no-repeat;
					background-position: center center;
					opacity: 0.5;
					// content: "▲";
					// content: "▲"/"";
				}
				&:after {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					top: 50%;
					width: 8px;
					height: 8px;
					content: '';
					background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgOSA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMi4zMDQ3NSAyLjY5NTI3QzIuMDQ0NCAyLjQzNDkzIDEuNjIyMjkgMi40MzQ5MyAxLjM2MTkzIDIuNjk1MjdDMS4xMDE1OSAyLjk1NTYgMS4xMDE1OSAzLjM3NzczIDEuMzYxOTMgMy42MzgwNkw0LjAyODYxIDYuMzA0NzNDNC4yODg5NCA2LjU2NTA3IDQuNzExMDcgNi41NjUwNyA0Ljk3MTQxIDYuMzA0NzNMNy42MzgwOCAzLjYzODA2QzcuODk4NDEgMy4zNzc3MyA3Ljg5ODQxIDIuOTU1NiA3LjYzODA4IDIuNjk1MjdDNy4zNzc3NCAyLjQzNDkzIDYuOTU1NjEgMi40MzQ5MyA2LjY5NTI4IDIuNjk1MjdMNC41MDAwMSA0Ljg5MDUzTDIuMzA0NzUgMi42OTUyN1oiIGZpbGw9IiM4QzlDQkIiLz4KPC9zdmc+Cg==");
					background-repeat: no-repeat;
					background-position: center center;
					// content: "▼";
					// content: "▼"/"";
					opacity: 1;
				}
			}
			>th.sorting_asc_disabled {
				cursor: pointer;
				position: relative;
				padding-right: 26px;
				&:before {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					bottom: 50%;
					content: "▲";
					content: "▲"/"";
					display: none;
				}
				&:after {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					top: 50%;
					content: "▼";
					content: "▼"/"";
				}
			}
			>th.sorting_desc_disabled {
				cursor: pointer;
				position: relative;
				padding-right: 26px;
				&:before {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					bottom: 50%;
					content: "▲";
					content: "▲"/"";
				}
				&:after {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					top: 50%;
					content: "▼";
					content: "▼"/"";
					display: none;
				}
			}
			>td.sorting {
				cursor: pointer;
				position: relative;
				padding-right: 26px;
				&:before {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					bottom: 50%;
					content: "▲";
					content: "▲"/"";
				}
				&:after {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					top: 50%;
					content: "▼";
					content: "▼"/"";
				}
			}
			>td.sorting_asc {
				cursor: pointer;
				position: relative;
				padding-right: 26px;
				&:before {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					bottom: 50%;
					content: "▲";
					content: "▲"/"";
					opacity: 0.6;
				}
				&:after {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					top: 50%;
					content: "▼";
					content: "▼"/"";
				}
			}
			>td.sorting_desc {
				cursor: pointer;
				position: relative;
				padding-right: 26px;
				&:before {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					bottom: 50%;
					content: "▲";
					content: "▲"/"";
				}
				&:after {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					top: 50%;
					content: "▼";
					content: "▼"/"";
					opacity: 0.6;
				}
			}
			>td.sorting_asc_disabled {
				cursor: pointer;
				position: relative;
				padding-right: 26px;
				&:before {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					bottom: 50%;
					content: "▲";
					content: "▲"/"";
					display: none;
				}
				&:after {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					top: 50%;
					content: "▼";
					content: "▼"/"";
				}
			}
			>td.sorting_desc_disabled {
				cursor: pointer;
				position: relative;
				padding-right: 26px;
				&:before {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					bottom: 50%;
					content: "▲";
					content: "▲"/"";
				}
				&:after {
					position: absolute;
					display: block;
					opacity: 0.125;
					right: 10px;
					line-height: 9px;
					font-size: 0.8em;
					top: 50%;
					content: "▼";
					content: "▼"/"";
					display: none;
				}
			}
			>th {
				&:active {
					outline: none;
				}
			}
			>td {
				&:active {
					outline: none;
				}
			}
		}
		th {
			font-size: 12px;
			text-align: left;
			font-weight: normal;
			color:#8C9CBB;
			letter-spacing: -0.12px;
		}
		td {
			font-size: 12px;
			text-align: left;
		}
		th.dt-head-left {
			text-align: left;
		}
		td.dt-head-left {
			text-align: left;
		}
		th.dt-head-center {
			text-align: center;
		}
		td.dt-head-center {
			text-align: center;
		}
		th.dt-head-right {
			text-align: right;
		}
		td.dt-head-right {
			text-align: right;
		}
		th.dt-head-justify {
			text-align: justify;
		}
		td.dt-head-justify {
			text-align: justify;
		}
		th.dt-head-nowrap {
			white-space: nowrap;
		}
		td.dt-head-nowrap {
			white-space: nowrap;
		}
	}
	th.dt-left {
		text-align: left;
	}
	td.dt-left {
		text-align: left;
	}
	th.dt-center {
		text-align: center;
	}
	td.dt-center {
		text-align: center;
	}
	td.dataTables_empty {
		text-align: center;
	}
	th.dt-right {
		text-align: right;
	}
	td.dt-right {
		text-align: right;
	}
	th.dt-justify {
		text-align: justify;
	}
	td.dt-justify {
		text-align: justify;
	}
	th.dt-nowrap {
		white-space: nowrap;
	}
	td.dt-nowrap {
		white-space: nowrap;
	}
	tfoot {
		th {
			text-align: left;
			font-weight: bold;
		}
		td {
			text-align: left;
		}
		th.dt-head-left {
			text-align: left;
		}
		td.dt-head-left {
			text-align: left;
		}
		th.dt-head-center {
			text-align: center;
		}
		td.dt-head-center {
			text-align: center;
		}
		th.dt-head-right {
			text-align: right;
		}
		td.dt-head-right {
			text-align: right;
		}
		th.dt-head-justify {
			text-align: justify;
		}
		td.dt-head-justify {
			text-align: justify;
		}
		th.dt-head-nowrap {
			white-space: nowrap;
		}
		td.dt-head-nowrap {
			white-space: nowrap;
		}
	}
	tbody {
		th.dt-body-left {
			text-align: left;
		}
		td.dt-body-left {
			text-align: left;
		}
		th.dt-body-center {
			text-align: center;
		}
		td.dt-body-center {
			text-align: center;
		}
		th.dt-body-right {
			text-align: right;
		}
		td.dt-body-right {
			text-align: right;
		}
		th.dt-body-justify {
			text-align: justify;
		}
		td.dt-body-justify {
			text-align: justify;
		}
		th.dt-body-nowrap {
			white-space: nowrap;
		}
		td.dt-body-nowrap {
			white-space: nowrap;
		}
		tr {
			background-color: transparent;
			td{
				&:first-child{
					padding-left:24px;
				}
			}
		}
		tr.selected {
			>* {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.9);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.9);
				color: rgb(255, 255, 255);
				color: rgb(var(--dt-row-selected-text));
			}
			a {
				color: rgb(9, 10, 11);
				color: rgb(var(--dt-row-selected-link));
			}
		}
		th {
			padding: 8px 10px;
		}
		td {
			padding: 12px 10px;
			font-weight: 500;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			letter-spacing: -0.14px;
		}
	}
	width: 100%;
	margin: 0 auto;
	clear: both;
	border-collapse: separate;
	border-spacing: 0;
	>thead {
		>tr {
			>th {
				padding: 8px 10px;
				border-bottom: 1px solid #E3E7EF;
				&:first-child{
					padding-left: 24px;
				}
				&:active {
					outline: none;
				}
			}
			>td {
				padding: 10px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.3);
				&:active {
					outline: none;
				}
			}
		}
	}
	>tfoot {
		>tr {
			>th {
				padding: 10px 10px 6px 10px;
				border-top: 1px solid #E3E7EF;
			}
			>td {
				padding: 10px 10px 6px 10px;
				border-top: 1px solid #E3E7EF;
			}
		}
	}
	th {
		box-sizing: content-box;
	}
	td {
		box-sizing: content-box;
	}
}
html.dark {
	table.dataTable {
		td.dt-control {
			&:before {
				color: rgba(255, 255, 255, 0.5);
			}
		}
		tr.dt-hasChild {
			td.dt-control {
				&:before {
					color: rgba(255, 255, 255, 0.5);
				}
			}
		}
		>thead {
			>tr {
				>th {
					border-bottom: 1px solid #E3E7EF;
					&:active {
						outline: none;
					}
				}
				>td {
					border-bottom: 1px solid #E3E7EF;
					&:active {
						outline: none;
					}
				}
			}
		}
		>tfoot {
			>tr {
				>th {
					border-top: 1px solid #E3E7EF;
				}
				>td {
					border-top: 1px solid #E3E7EF;
				}
			}
		}
	}
	--dt-row-hover: 255, 255, 255;
	--dt-row-stripe: 255, 255, 255;
	--dt-column-ordering: 255, 255, 255;
	table.dataTable.row-border {
		>tbody {
			>tr {
				>th {
					border-top: 1px solid rgb(64, 67, 70);
				}
				>td {
					border-top: 1px solid rgb(64, 67, 70);
				}
			}
			>tr.selected {
				+ {
					tr.selected {
						>td {
							border-top-color: #0257d5;
						}
					}
				}
			}
		}
	}
	table.dataTable.display {
		>tbody {
			>tr {
				>th {
					border-top: 1px solid rgb(64, 67, 70);
				}
				>td {
					border-top: 1px solid rgb(64, 67, 70);
				}
			}
			>tr.selected {
				+ {
					tr.selected {
						>td {
							border-top-color: #0257d5;
						}
					}
				}
			}
		}
	}
	table.dataTable.cell-border {
		>tbody {
			>tr {
				>th {
					border-top: 1px solid rgb(64, 67, 70);
					border-right: 1px solid rgb(64, 67, 70);
					&:first-child {
						border-left: 1px solid rgb(64, 67, 70);
					}
				}
				>td {
					border-top: 1px solid rgb(64, 67, 70);
					border-right: 1px solid rgb(64, 67, 70);
					&:first-child {
						border-left: 1px solid rgb(64, 67, 70);
					}
				}
			}
		}
	}
	.dataTables_wrapper {
		.dataTables_filter {
			input {
				border: 1px solid rgba(255, 255, 255, 0.2);
				background-color: var(--dt-html-background);
			}
		}
		.dataTables_length {
			select {
				border: 1px solid rgba(255, 255, 255, 0.2);
				background-color: var(--dt-html-background);
			}
		}
		.dataTables_paginate {
			.paginate_button.current {
				border: 1px solid #E3E7EF;
				background: rgba(255, 255, 255, 0.15);
				&:hover {
					border: 1px solid #E3E7EF;
					background: rgba(255, 255, 255, 0.15);
				}
			}
			.paginate_button.disabled {
				color: #666 !important;
				&:hover {
					color: #666 !important;
				}
				&:active {
					color: #666 !important;
				}
			}
			.paginate_button {
				&:hover {
					border: 1px solid rgb(53, 53, 53);
					background: rgb(53, 53, 53);
				}
				&:active {
					background: #3a3a3a;
				}
			}
		}
	}
}
div.dataTables_scrollBody {
	>table.dataTable {
		>thead {
			>tr {
				>th {
					&:before {
						display: none;
					}
					&:after {
						display: none;
					}
				}
				>td {
					&:before {
						display: none;
					}
					&:after {
						display: none;
					}
				}
			}
		}
	}
}
div.dataTables_processing {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 200px;
	margin-left: -100px;
	margin-top: -26px;
	text-align: center;
	padding: 2px;
	z-index: 10;
	>div {
		&:last-child {
			position: relative;
			width: 80px;
			height: 15px;
			margin: 1em auto;
			>div {
				position: absolute;
				top: 0;
				width: 13px;
				height: 13px;
				border-radius: 50%;
				background: rgb(13, 110, 253);
				background: rgb(var(--dt-row-selected));
				animation-timing-function: cubic-bezier(0, 1, 1, 0);
				&:nth-child(1) {
					left: 8px;
					animation: datatables-loader-1 0.6s infinite;
				}
				&:nth-child(2) {
					left: 8px;
					animation: datatables-loader-2 0.6s infinite;
				}
				&:nth-child(3) {
					left: 32px;
					animation: datatables-loader-2 0.6s infinite;
				}
				&:nth-child(4) {
					left: 56px;
					animation: datatables-loader-3 0.6s infinite;
				}
			}
		}
	}
}
table.dataTable.nowrap {
	th {
		white-space: nowrap;
	}
	td {
		white-space: nowrap;
	}
}
table.dataTable.row-border {
	>tbody {
		>tr {
			>th {
				border-top: 1px solid rgba(0, 0, 0, 0.15);
			}
			>td {
				border-top: 1px solid rgba(0, 0, 0, 0.15);
			}
			&:first-child {
				>th {
					border-top: none;
				}
				>td {
					border-top: none;
				}
			}
		}
		>tr.selected {
			+ {
				tr.selected {
					>td {
						border-top-color: #0262ef;
					}
				}
			}
		}
	}
}
table.dataTable.display {
	>tbody {
		>tr {
			>th {
				border-top: 1px solid rgba(0, 0, 0, 0.15);
			}
			>td {
				border-top: 1px solid rgba(0, 0, 0, 0.15);
			}
			&:first-child {
				>th {
					border-top: none;
				}
				>td {
					border-top: none;
				}
			}
			&:hover {
				>* {
					box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.035);
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.035);
				}
			}
		}
		>tr.selected {
			+ {
				tr.selected {
					>td {
						border-top-color: #0262ef;
					}
				}
			}
			&:hover {
				>* {
					box-shadow: inset 0 0 0 9999px #0d6efd !important;
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 1) !important;
				}
			}
		}
		>tr.odd {
			>* {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.023);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-stripe), 0.023);
			}
			>.sorting_1 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.054);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.054);
			}
			>.sorting_2 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.047);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.047);
			}
			>.sorting_3 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.039);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.039);
			}
		}
		>tr.odd.selected {
			>* {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.923);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.923);
			}
			>.sorting_1 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.954);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.954);
			}
			>.sorting_2 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.947);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.947);
			}
			>.sorting_3 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.939);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.939);
			}
		}
		tr {
			>.sorting_1 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.019);
			}
			>.sorting_2 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.019);
			}
			>.sorting_3 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.019);
			}
		}
		tr.selected {
			>.sorting_1 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.919);
			}
			>.sorting_2 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.919);
			}
			>.sorting_3 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.919);
			}
		}
		>tr.even {
			>.sorting_1 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.019);
			}
			>.sorting_2 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.011);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.011);
			}
			>.sorting_3 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.003);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.003);
			}
		}
		>tr.even.selected {
			>.sorting_1 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.919);
			}
			>.sorting_2 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.911);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.911);
			}
			>.sorting_3 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.903);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.903);
			}
		}
	}
	tbody {
		tr {
			&:hover {
				>.sorting_1 {
					box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.082);
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.082);
				}
				>.sorting_2 {
					box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.074);
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.074);
				}
				>.sorting_3 {
					box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.062);
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.062);
				}
			}
			&:hover.selected {
				>.sorting_1 {
					box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.982);
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.982);
				}
				>.sorting_2 {
					box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.974);
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.974);
				}
				>.sorting_3 {
					box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.962);
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.962);
				}
			}
		}
	}
}
table.dataTable.cell-border {
	>tbody {
		>tr {
			>th {
				border-top: 1px solid rgba(0, 0, 0, 0.15);
				border-right: 1px solid rgba(0, 0, 0, 0.15);
				&:first-child {
					border-left: 1px solid rgba(0, 0, 0, 0.15);
				}
			}
			>td {
				border-top: 1px solid rgba(0, 0, 0, 0.15);
				border-right: 1px solid rgba(0, 0, 0, 0.15);
				&:first-child {
					border-left: 1px solid rgba(0, 0, 0, 0.15);
				}
			}
			&:first-child {
				>th {
					border-top: none;
				}
				>td {
					border-top: none;
				}
			}
		}
	}
}
table.dataTable.stripe {
	>tbody {
		>tr.odd {
			>* {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.023);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-stripe), 0.023);
			}
		}
		>tr.odd.selected {
			>* {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.923);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.923);
			}
		}
	}
}
table.dataTable.hover {
	>tbody {
		>tr {
			&:hover {
				>* {
					box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.035);
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.035);
				}
			}
		}
		>tr.selected {
			&:hover {
				>* {
					box-shadow: inset 0 0 0 9999px #0d6efd !important;
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 1) !important;
				}
			}
		}
	}
}
table.dataTable.order-column {
	>tbody {
		tr {
			>.sorting_1 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.019);
			}
			>.sorting_2 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.019);
			}
			>.sorting_3 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.019);
			}
		}
		tr.selected {
			>.sorting_1 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.919);
			}
			>.sorting_2 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.919);
			}
			>.sorting_3 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.919);
			}
		}
	}
}
table.dataTable.order-column.stripe {
	>tbody {
		>tr.odd {
			>.sorting_1 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.054);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.054);
			}
			>.sorting_2 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.047);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.047);
			}
			>.sorting_3 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.039);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.039);
			}
		}
		>tr.odd.selected {
			>.sorting_1 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.954);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.954);
			}
			>.sorting_2 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.947);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.947);
			}
			>.sorting_3 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.939);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.939);
			}
		}
		>tr.even {
			>.sorting_1 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.019);
			}
			>.sorting_2 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.011);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.011);
			}
			>.sorting_3 {
				box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.003);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.003);
			}
		}
		>tr.even.selected {
			>.sorting_1 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.919);
			}
			>.sorting_2 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.911);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.911);
			}
			>.sorting_3 {
				box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.903);
				box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.903);
			}
		}
	}
}
table.dataTable.order-column.hover {
	tbody {
		tr {
			&:hover {
				>.sorting_1 {
					box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.082);
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.082);
				}
				>.sorting_2 {
					box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.074);
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.074);
				}
				>.sorting_3 {
					box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.062);
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.062);
				}
			}
			&:hover.selected {
				>.sorting_1 {
					box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.982);
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.982);
				}
				>.sorting_2 {
					box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.974);
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.974);
				}
				>.sorting_3 {
					box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.962);
					box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.962);
				}
			}
		}
	}
}
table.dataTable.no-footer {
	border-bottom: 1px solid #E3E7EF;
}
table.dataTable.compact {
	thead {
		th {
			padding: 4px;
		}
		td {
			padding: 4px;
		}
	}
	tfoot {
		th {
			padding: 4px;
		}
		td {
			padding: 4px;
		}
	}
	tbody {
		th {
			padding: 4px;
		}
		td {
			padding: 4px;
		}
	}
}
.dataTables_wrapper {
	position: relative;
	clear: both;
	.dataTables_length {
		float: left;
		color: inherit;
		select {
			border: 1px solid #aaa;
			border-radius: 3px;
			padding: 5px;
			background-color: transparent;
			color: inherit;
			padding: 4px;
		}
	}
	.dataTables_filter {
		float: right;
		text-align: right;
		color: inherit;
		input {
			border: 1px solid #aaa;
			border-radius: 3px;
			padding: 5px;
			background-color: transparent;
			color: inherit;
			margin-left: 3px;
		}
	}
	.dataTables_info {
		clear: both;
		// float: left;
		// padding-top: 0.755em;
		color: inherit;
	}
	.dataTables_paginate {
		// float: right;
		// text-align: right;
		// padding-top: 0.25em;
		display: flex;
			justify-content: center;
			align-items: center;
		color: #8C9CBB;
		.paginate_button {
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			// min-width: 1.5em;
			// padding: 0.5em 1em;
			margin: 0 10px;
			text-align: center;
			text-decoration: none !important;
			cursor: pointer;
			color: inherit !important;
			// border: 1px solid transparent;
			// border-radius: 2px;
			background: transparent;
			&:hover {
				color: white !important;
				// border: 1px solid #111;
				// background-color: #111;
				// background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
				// background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
				// background: -moz-linear-gradient(top, #585858 0%, #111 100%);
				// background: -ms-linear-gradient(top, #585858 0%, #111 100%);
				// background: -o-linear-gradient(top, #585858 0%, #111 100%);
				// background: linear-gradient(to bottom, #585858 0%, #111 100%);
			}
			&:active {
				outline: none;
				// background-color: #0c0c0c;
				// background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
				// background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
				// background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
				// background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
				// background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
				// background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
				// box-shadow: inset 0 0 3px #111;
			}
		}
		.paginate_button.current {
			color: #1F293C !important;
			font-weight: 700;
			// border: 1px solid rgba(0, 0, 0, 0.3);
			// background-color: rgba(0, 0, 0, 0.05);
			// background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(230, 230, 230, 0.05)), color-stop(100%, rgba(0, 0, 0, 0.05)));
			// background: -webkit-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
			// background: -moz-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
			// background: -ms-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
			// background: -o-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
			// background: linear-gradient(to bottom, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
			&:hover {
				color: #1F293C !important;
				// border: 1px solid rgba(0, 0, 0, 0.3);
				// background-color: rgba(0, 0, 0, 0.05);
				// background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(230, 230, 230, 0.05)), color-stop(100%, rgba(0, 0, 0, 0.05)));
				// background: -webkit-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
				// background: -moz-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
				// background: -ms-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
				// background: -o-linear-gradient(top, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
				// background: linear-gradient(to bottom, rgba(230, 230, 230, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
			}
		}
		.paginate_button{
			&:hover{
				color: #8C9CBB !important;
			}
			&.previous, &.next{
				font-size: 22px;
			}
			&.previous{
				width: 20px;
				height: 20px;
				margin:0 22px 0 0;
				overflow: hidden;
				text-indent: -999px;
				+ span{
					display: flex;
				}
			}
			&.next{
				width: 20px;
				height: 20px;
				margin:0 0 0 22px;
				overflow: hidden;
				text-indent: -999px;
			}
		}
		.paginate_button.disabled {
			cursor: default;
			color: #8C9CBB !important;
			// border: 1px solid transparent;
			// background: transparent;
			// box-shadow: none;
			&:hover {
				cursor: default;
				color: #8C9CBB !important;
				// color: #666 !important;
				// border: 1px solid transparent;
				// background: transparent;
				// box-shadow: none;
			}
			&:active {
				cursor: default;
				// color: #666 !important;
				// border: 1px solid transparent;
				// background: transparent;
				// box-shadow: none;
			}
		}
		.ellipsis {
			padding: 0 1em;
		}
	}
	.dataTables_processing {
		color: inherit;
	}
	.dataTables_scroll {
		clear: both;
		div.dataTables_scrollBody {
			-webkit-overflow-scrolling: touch;
			>table {
				>thead {
					>tr {
						>th {
							vertical-align: middle;
							>div.dataTables_sizing {
								height: 0;
								overflow: hidden;
								margin: 0 !important;
								padding: 0 !important;
							}
						}
						>td {
							vertical-align: middle;
							>div.dataTables_sizing {
								height: 0;
								overflow: hidden;
								margin: 0 !important;
								padding: 0 !important;
							}
						}
					}
				}
				>tbody {
					>tr {
						>th {
							vertical-align: middle;
							>div.dataTables_sizing {
								height: 0;
								overflow: hidden;
								margin: 0 !important;
								padding: 0 !important;
							}
						}
						>td {
							vertical-align: middle;
							>div.dataTables_sizing {
								height: 0;
								overflow: hidden;
								margin: 0 !important;
								padding: 0 !important;
							}
						}
					}
				}
			}
		}
	}
	&:after {
		visibility: hidden;
		display: block;
		content: "";
		clear: both;
		height: 0;
	}
}
.dataTables_wrapper.no-footer {
	.dataTables_scrollBody {
		border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	}
	div.dataTables_scrollHead {
		table.dataTable {
			border-bottom: none;
		}
	}
	div.dataTables_scrollBody {
		>table {
			border-bottom: none;
		}
	}
}
@media screen and (max-width: 767px) {
	.dataTables_wrapper {
		.dataTables_info {
			float: none;
			text-align: center;
		}
		.dataTables_paginate {
			float: none;
			text-align: center;
			margin-top: 0.5em;
		}
	}
}
@media screen and (max-width: 640px) {
	.dataTables_wrapper {
		.dataTables_length {
			float: none;
			text-align: center;
		}
		.dataTables_filter {
			float: none;
			text-align: center;
			margin-top: 0.5em;
		}
	}
}
