/* .sidebar_Nav_List > li:not(:last-child) {
    @apply mb-1;
} */
.sidebar_Nav_List li a {
    transition : all 0.3s ease;
    display: flex;
    align-items: center;
    width: 100%;
    color: #667085;
    font-size: 12px;
    line-height: 16px;
    border-radius: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;
    /* @apply flex py-[6px] px-3 rounded-md  items-center text-xs w-full text-[#637085]; */
}
.sidebar_Nav_List li a .icon svg{
    @apply  object-contain w-[20px] h-[20px];
}
.sidebar_Nav_List li a .text{
    @apply font-medium flex flex-auto items-center ms-2.5;
}

.sidebar_Nav_List li a:hover{
    @apply text-[#101828]  ;
}
.sidebar_Nav_List li a.active {
    @apply text-[#101828] bg-[#F1F1F1];
}


.sidebar_Nav_List .active {
    color: #101828 !important;
    background-color: #F1F1F1 !important;
    border-radius: 6px !important;
  }
  
  .active-nav {
    color: #101828 !important;
    background-color: #F1F1F1 !important;
    border-radius: 6px !important;
  }
  
  .sidebar_Nav_List .active .link_icon {
    color: #101828 !important;
    background-color: #F1F1F1 !important;
    border-radius: 6px !important;
  }

.footer_nav a {
    @apply flex py-2 px-2 rounded-md transition-all ease-in duration-150 items-center text-xs w-full text-gray-600 ;
}
.footer_nav a .icon{
    @apply text-xl w-[18px] object-contain;
}
.footer_nav a .text{
    @apply font-semibold flex flex-auto items-center ms-2;
}

.footer_nav a:hover,
.footer_nav a.active {
    @apply text-mainTextColor bg-sidebarMenuDropDownBg;
}


.sidebar_Nav_List li a .text > .nav-badge {
    @apply flex items-center font-normal !important;
}
.badge-brand{
    @apply bg-blue-50 border border-blue-200  text-blue-700 rounded-full !important;
}
.sidebar_Nav_List li a .text > .badge > span {
    @apply -tracking-[0.12px] leading-[150%] whitespace-nowrap;
}

