.location-card-heading-detail {
    @apply text-gray-900 font-semibold text-sm truncate mb-1;
}

.location-badge {
    @apply flex items-center gap-1 text-xs leading-[18px] font-medium w-fit;
}

.custom-icon-small {
    @apply h-4 w-4 cursor-pointer text-gray-500;
}

.location-form-label-heading {
    @apply font-semibold text-sm text-gray-700;
}

.location-form-label-subheading {
    @apply text-xs text-secondaryTxtColor leading-[18px] font-normal;
}

.location-header-menu {
    @apply flex items-center cursor-pointer gap-2 py-[9px] px-2.5 w-full text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 hover:text-gray-800;
}
.viewLocation .p-datatable-wrapper {
    @apply border  border-gray-200 rounded-xl;
}
.viewLocation tbody tr {
    @apply min-h-[64px] border-b  border-gray-200 h-[64px];
}
.viewLocation tbody tr:last-child {
    @apply border-0;
}

.location_menu svg {
    @apply h-4 w-4 text-gray-500;
}
