$primary: #1642c5;
$mainTextColor: #1f293c;
$secondaryTxtColor: #8c9cbb;
$baseBg: #fbfbff;
$inputBg: #eff1f5;
$green: #5eac20;
$lightGreen: #cbf9e1;
$borderGray: #dbdbdb;
$lineColor: #e3e7ef;
$lineLightColor: #eeeef1;
$dangerText: #e33b3b;
$dangerBgLight: #f9e0e0;
$dangerBg: #f1d0d0;
$white: #ffffff;
$black: #000000;

.custom-calendar .rbc-allday-cell {
    display: none;
}

.custom-calendar {
    .rbc-day-slot {
        min-width: 210px !important;

        @media (min-width: 280px) and (max-width: 1700px) {
            min-width: 180px !important;
            // width: 153px !important;
            // max-width: 153px !important;
        }
    }
}
.week-calendar .rbc-allday-cell {
    display: none;
}

// .close_calendar {
//     // width: calc(100% - 37px);
//     // margin-right: 37px;

//     .rbc-time-view {
//         // width: 1180px;
//         overflow-x: auto;
//         border: none;
//         // scrollbar-width: none;

//         &::-webkit-scrollbar {
//             width: 100%;
//             height: 8px;
//         }
//         &::-webkit-scrollbar-track {
//             background: #ebebeb;
//             cursor: pointer;
//         }
//         &::-webkit-scrollbar-thumb {
//             border-radius: 5px;
//             cursor: pointer;
//             background: #c4c4c499;
//         }

//         .rbc-time-header {
//             overflow: unset;
//             top: 0;
//             width: 100%;

//             .rbc-time-header-gutter {
//                 border-bottom: 1px solid #ddd;
//                 background-color: white;
//             }

//             .rbc-time-header-content {
//                 background-color: white;
//             }
//         }

//         .rbc-time-content {
//             overflow-y: unset;
//             scrollbar-width: none;
//             // max-height: calc(100vh - 240px);
//             width: 100%;
//             .rbc-time-gutter {
//                 background-color: white;
//             }
//         }
//     }
// }
// .close_calendar.calendar-without-resource {
//     .rbc-time-header {
//         width: 140%;
//     }
//     .rbc-time-content {
//         width: 140%;
//     }
// }
// .close_calendar.calendar-without-resource.calendar-with-resource {
//     .rbc-time-header {
//         width: 100%;
//     }
//     .rbc-time-content {
//         width: 100%;
//     }
// }
// .open_calendar {
//     width: calc(100% - 335px);
//     margin-right: 335px;

//     @media (max-width: 1680px) {
//         width: calc(100% - 336px);
//         margin-right: 336px;
//     }

//     .rbc-time-view {
//         // width: 1180px;
//         overflow-x: scroll;
//         border: none;
//         // scrollbar-width: none;

//         // &::-webkit-scrollbar {
//         //     opacity: 0;
//         // }

//         &::-webkit-scrollbar {
//             width: 100%;
//             cursor: pointer;
//             height: 8px;
//         }
//         &::-webkit-scrollbar-track {
//             background: #ebebeb;
//             cursor: pointer;
//         }
//         &::-webkit-scrollbar-thumb {
//             border-radius: 5px;
//             cursor: pointer;
//             background: #c4c4c499;
//         }

//         .rbc-time-header {
//             overflow-x: unset;
//             top: 0;
//             width: 100%;
//             scrollbar-width: none;

//             .rbc-time-header-gutter {
//                 border-bottom: 1px solid #ddd;
//                 background-color: white;
//             }

//             .rbc-time-header-content {
//                 background-color: white;
//             }
//         }

//         .rbc-time-content {
//             overflow-y: unset;
//             scrollbar-width: none;
//             width: 100%;
//             .rbc-time-gutter {
//                 background-color: white;
//             }
//         }
//     }
// }

.rbc-time-view {
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
    border-right: 0;
    .rbc-time-header {
        background-color: $white;
        position: sticky;
        top: 65px;
        z-index: 9;

        .rbc-time-header-content {
            border-bottom: 0;
            padding: 0;
            min-width: 210px !important;

            @media (min-width: 280px) and (max-width: 1700px) {
                min-width: 180px !important;
                // width: 153px !important;
                // max-width: 153px !important;
            }
            + .rbc-time-header-content {
                margin-left: 0;
            }
            .rbc-time-header-cell {
                border-bottom: 0;
                .rbc-header {
                    padding: 0;
                    min-width: 210px !important;

                    @media (min-width: 280px) and (max-width: 1700px) {
                        min-width: 180px !important;
                        width: 180px !important;
                        // max-width: 153px !important;
                    }
                    background-color: white;

                    .rbc-button-link {
                        width: 100%;
                        text-align: left;
                        border-right: 1px solid #ddd;
                        padding: 8px 8px 8px 20px;
                        .day-name {
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            color: #475467;
                            text-transform: uppercase;
                        }
                        .day-date {
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 28px;
                            color: #101828;
                        }
                    }
                    &.rbc-today {
                        background-color: $white;
                        .rbc-button-link {
                            .day-name,
                            .day-date {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
        // > div {
        //     &:nth-child(2) {
        //         border-left-color: transparent;
        //     }
        // }
    }
    .rbc-timeslot-group {
        min-height: 109px;
    }
    .rbc-time-content {
        border-top: 0;
        z-index: 3;
        .rbc-time-gutter {
            background-color: white;
            // border: 0;
            min-width: 64px;
            .rbc-timeslot-group {
                // border-color: #F0F0F0;
                // border: transparent;
                .rbc-time-slot {
                    text-align: center;
                    // border-color: transparent;
                    .rbc-label {
                        font-size: 12px;
                        font-weight: 600;
                        text-transform: uppercase;
                        color: #101828;
                        display: flex;
                        margin-top: 5px;
                        padding-left: 0;
                        line-height: 18px;
                        min-width: 79px;
                        justify-content: center;
                    }
                }
            }
            // + .rbc-day-slot {
            // .rbc-timeslot-group,
            // .rbc-events-container {
            //     border-left-color: transparent;
            // }
            // }
        }
        .rbc-time-slot {
            min-height: 45px;
        }
        .rbc-day-slot {
            .rbc-timeslot-group {
                border-color: #e0e0e0;
                .rbc-time-slot {
                    border-color: #f0f0f0;
                    &:first-child {
                        border-color: transparent;
                    }
                }
            }
            .rbc-events-container {
                margin-right: 0;
                min-width: 210px !important;

                @media (min-width: 280px) and (max-width: 1700px) {
                    min-width: 180px !important;
                    // width: 153px !important;
                    // max-width: 153px !important;
                }
                .rbc-event {
                    border: 0px solid black;
                    background-color: transparent;
                    padding: 4px;
                    outline: none !important;
                    > .rbc-event-label {
                        display: none;
                    }
                    .event-container-wrap {
                        // background-color: $white;
                        border-radius: 6px;
                        box-shadow: 0px 2px 11px -2px #1018280f;
                        // margin: 4px;
                        // border: 2px solid #ddfae7;

                        // .title-color {
                        //     color: #101828;
                        // }
                        // &.event-confirmed {
                        //     border: 2px solid #b9e6fe;
                        // }
                        // &.event-pending {
                        //     background-color: $white;
                        //     border: 1.5px solid #fee190;
                        //     box-shadow: 0px 2px 11px -2px #1018280f;
                        //     .title-color {
                        //         color: #101828;
                        //     }
                        // }
                        // &.event-declined {
                        //     background-color: #ffffff;
                        //     border: 2px solid #eaecf0;
                        //     .title-color {
                        //         color: #98a2b3;
                        //     }
                        // }
                        // &.event-completed {
                        //     border: 2px solid #ddfae7;
                        //     background-color: $white;
                        //     .title-color {
                        //         color: #101828;
                        //     }
                        // }
                        // &.event-canceled {
                        //     background-color: #ffffff;
                        //     border: 2px solid #fef3f2;
                        //     .title-color {
                        //         color: #98a2b3;
                        //     }
                        // }
                        // &.event-current {
                        //     border: 2px solid #d3defd;
                        //     background-color: #edf2ff;
                        //     .title-color {
                        //         color: #0e34a5;
                        //     }
                        // }
                        // &.event-no_show {
                        //     border: 2px solid #d5d9eb;
                        //     background-color: #ffffff;
                        //     .title-color {
                        //         color: #98a2b3;
                        //     }
                        // }
                    }
                }
            }
        }
    }
    .rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
        min-width: 210px !important;
        @media (min-width: 280px) and (max-width: 1700px) {
            min-width: 180px !important;
            // width: 153px !important;
            // max-width: 153px !important;
        }
        .rbc-timeslot-group {
            background-color: $white;
        }
    }

    .rbc-slot-selection {
        span {
            flex-direction: row;
            color: #067647;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            height: 22px;
        }
        span:before {
            color: #07944e;
            content: url(../../../src/assets/svgs/dot.svg);
            right: 6px;
            top: 4px;
            position: absolute;
        }
        background-color: #ECFDF3;
        border-radius: 6px;
        box-shadow: 0px 2px 11px -2px rgba(16, 24, 40, 0.0588235294);
        border: 1px solid #ABEFC6;
        padding: 6px 6px;
        border-radius: 6px;
        cursor: pointer;
        font-size: 12px;
        transition: background-color 0.3s;
        display: flex;
        margin: 4px;
        max-width: calc(100% - 8px);
    }

    .rbc-selected {
        background-color: red;
    }

    &.rbc-time-view-resources {
        .rbc-time-header {
            // border-color: #F0F0F0;
            // border-color: #E0E0E0;
            .rbc-time-header-content {
                // border-bottom: 1px solid #e0e0e0;
                .rbc-row.rbc-row-resource {
                    .rbc-header {
                        border-bottom: 0 !important;
                    }
                }
            }
        }
    }
}

.rbc-current-time-indicator {
    background-color: #3570f8;
}
.rbc-time-content {
    .rbc-time-gutter {
        .rbc-now:first-child {
            .rbc-current-time-indicator {
                &::before {
                    content: ' ';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 6px solid #3570f8;
                    border-bottom: 6px solid transparent;
                    border-top: 6px solid transparent;
                    // border-left: transparent;
                    // transform: rotate(45deg);
                    top: -6px;
                    // left: -64px;
                }
                &::after {
                    content: ' ';
                    position: absolute;
                    height: 1px;
                    background-color: #3570f8;
                    top: 0;
                    left: -64px;
                    width: 64px;
                }
            }
        }
    }
    .rbc-day-slot.rbc-today {
        .rbc-timeslot-group {
            background-color: $white;
        }
    }
}
.rbc-time-view {
    &.rbc-time-view-resources {
        .rbc-now:nth-child(2) {
            .rbc-current-time-indicator {
                &::before {
                    content: ' ';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 6px solid #3570f8;
                    border-bottom: 6px solid transparent;
                    border-top: 6px solid transparent;
                    // border-left: transparent;
                    // transform: rotate(45deg);
                    top: -6px;
                    // left: -64px;
                }
                &::after {
                    content: ' ';
                    position: absolute;
                    height: 1px;
                    background-color: #3570f8;
                    top: 0;
                    left: 0;
                    width: 64px;
                }
            }
        }
        .rbc-now:nth-child(3),
        .rbc-now:nth-child(4),
        .rbc-now:nth-child(5),
        .rbc-now:nth-child(6),
        .rbc-now:nth-child(7),
        .rbc-now:nth-child(8),
        .rbc-now:nth-child(9),
        .rbc-now:nth-child(10) {
            .rbc-current-time-indicator {
                &::before {
                    content: ' ';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 0px solid #3570f8;
                    border-bottom: 6px solid transparent;
                    border-top: 0px solid transparent;
                    // border-left: transparent;
                    // transform: rotate(45deg);
                    top: -6px;
                    // left: -64px;
                }
                &::after {
                    content: ' ';
                    position: absolute;
                    height: 1px;
                    background-color: #3570f8;
                    top: 0;
                    left: 0;
                    width: 64px;
                }
            }
        }
    }
    .rbc-now:nth-child(3),
    .rbc-now:nth-child(4),
    .rbc-now:nth-child(5),
    .rbc-now:nth-child(6),
    .rbc-now:nth-child(7),
    .rbc-now:nth-child(8),
    .rbc-now:nth-child(9),
    .rbc-now:nth-child(10) {
        .rbc-current-time-indicator {
            &::before {
                content: ' ';
                position: absolute;
                width: 0;
                height: 0;
                border-left: 6px solid #3570f8;
                border-bottom: 6px solid transparent;
                border-top: 6px solid transparent;
                // border-left: transparent;
                // transform: rotate(45deg);
                top: -6px;
                // left: -64px;
            }
            &::after {
                content: ' ';
                position: absolute;
                height: 1px;
                background-color: #3570f8;
                top: 0;
                left: 0;
                width: 64px;
            }
        }
    }
}
.calendar_sideModal {
    height: calc(100vh - 95px);
}
@media (max-width: 1769px) {
    .calendar_sideModal {
        height: calc(100vh - 91px);
    }
}
.rbc-time-content {
    // overflow-y: unset;
    scrollbar-width: none;
    max-height: calc(100vh - 240px);
}
.rbc-time-header-gutter {
    background-color: transparent;
    // min-width: 80px !important;
}
.rbc-time-header {
    position: sticky;
    top: 158px;
    z-index: 10;
    background-color: $white;
}

.rbc-header + .rbc-header {
    border-left: 0px solid #ddd !important;
    border-right: 0px solid #ddd !important;
}
.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
    outline: 0px auto #3b99fc !important;
}
// .open_calendar .openCalendarSpacing {
//     margin-right: 16px;
// }
.rbc-time-content > * + * > * {
    border-left: 1px solid #ddd;
}

.rbc-time-content:nth-last-of-type(2) .rbc-time-content > * + * > * {
    border-right: 1px solid #ddd;
}
.rbc-label.rbc-time-header-gutter {
    min-width: 80px !important;
    max-width: 80px !important;
    width: 80px !important;
    border-bottom: 1px solid #ddd;
}

.calendar-with-resource .rbc-label.rbc-time-header-gutter {
    min-width: 80px !important;
    max-width: 80px !important;
    width: 80px !important;
}

// .rbc-time-view-resources .rbc-time-header {
//     overflow: unset;
// }

.week-calendar .rbc-time-header.rbc-overflowing {
    overflow-x: auto;
    scrollbar-width: none;
}
.week-calendar .rbc-time-header.rbc-overflowing::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
    display: none;
    scrollbar-width: none;
}
.week-calendar .rbc-time-content > .rbc-day-slot {
    min-width: 210px;
    @media (min-width: 280px) and (max-width: 1700px) {
        min-width: 180px !important;
    }
}

.week-calendar .rbc-time-view .rbc-time-content .rbc-time-gutter,
.week-calendar .rbc-label.rbc-time-header-gutter {
    position: sticky;
    left: 0;
    z-index: 99999;
    background: #ffffff;
    border-right: 1px solid #ddd;
    margin-right: -1px;
}

// no data saw css

.custom-calendar-no-data .rbc-time-header {
    display: none !important;
}

.custom-calendar-no-data .rbc-time-view {
    border: 0 !important;
    border: none !important;
}

.custom-calendar-no-data .rbc-time-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-bottom: 0px solid #e0e0e0;
}

.custom-calendar-no-data .rbc-time-content {
    scrollbar-width: none;
    max-height: calc(100vh - 138px);
}
