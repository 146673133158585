.app-page [type='text'][type='text'],
.app-page [type='email'],
.app-page [type='url'],
.app-page [type='password'],
.app-page [type='number'],
.app-page [type='date'],
.app-page [type='datetime-local'],
.app-page [type='month'],
.app-page [type='search'],
.app-page [type='tel'],
.app-page [type='time'],
.app-page [type='week'],
.app-page [multiple],
.app-page select,
.app-page .form-control {
    @apply  text-ellipsis ;
}

.app-page [type='text']::placeholder,
.app-page [type='email']::placeholder,
.app-page [type='url']::placeholder,
.app-page [type='password']::placeholder,
.app-page [type='number']::placeholder,
.app-page [type='date']::placeholder,
.app-page [type='datetime-local']::placeholder,
.app-page [type='month']::placeholder,
.app-page [type='search']::placeholder,
.app-page [type='tel']::placeholder,
.app-page [type='time']::placeholder,
.app-page [type='week']::placeholder,
.app-page [multiple]::placeholder,
.app-page select::placeholder,
.app-page .form-control::placeholder {
    @apply text-gray-500;
}

.app-page [type='text']:hover,
.app-page [type='email']:hover,
.app-page [type='url']:hover,
.app-page [type='password']:hover,
.app-page [type='number']:hover,
.app-page [type='date']:hover,
.app-page [type='datetime-local']:hover,
.app-page [type='month']:hover,
.app-page [type='search']:hover,
.app-page [type='tel']:hover,
.app-page [type='time']:hover,
.app-page [type='week']:hover,
.app-page [multiple]:hover,
.app-page select:hover,
.app-page .form-control:hover,
.app-page [type='text']:focus,
.app-page [type='email']:focus,
.app-page [type='url']:focus,
.app-page [type='password']:focus,
.app-page [type='number']:focus,
.app-page [type='date']:focus,
.app-page [type='datetime-local']:focus,
.app-page [type='month']:focus,
.app-page [type='search']:focus,
.app-page [type='tel']:focus,
.app-page [type='time']:focus,
.app-page [type='week']:focus,
.app-page [multiple]:focus,
.app-page select:focus,
.app-page .form-control:focus,
.app-page [type='text']:focus-visible,
.app-page [type='email']:focus-visible,
.app-page [type='url']:focus-visible,
.app-page [type='password']:focus-visible,
.app-page [type='number']:focus-visible,
.app-page [type='date']:focus-visible,
.app-page [type='datetime-local']:focus-visible,
.app-page [type='month']:focus-visible,
.app-page [type='search']:focus-visible,
.app-page [type='tel']:focus-visible,
.app-page [type='time']:focus-visible,
.app-page [type='week']:focus-visible,
.app-page [multiple]:focus-visible,
.app-page select:focus-visible,
.app-page .form-control:focus-visible {
    @apply text-gray-900 outline-0 shadow-none ring-0 !important;
    /* @apply text-gray-900 border-[#98a7c3] !outline-none !shadow !ring-0 !ring-transparent; */
}

.app-page .otp-input {
    @apply focus:ring-1 focus-within:ring-1 focus-visible:ring-1 !important;
}

/*-- textarea --*/
.app-page textarea {
    @apply !text-gray-900 !text-xs !leading-[18px] !rounded-lg !border border-borderPrimary;
}

.app-page textarea::placeholder {
    @apply text-gray-500;
}
.app-page textarea:hover,
.app-page textarea:focus,
.app-page textarea:focus-visible {
    @apply !text-gray-900 border border-[#98a7c3] !outline-none !shadow !ring-0 !ring-transparent;
}

.input-group {
    @apply relative flex border border-borderPrimary bg-white rounded-lg;
}
.input-group:hover, .input-group:focus, .input-group:focus-visible .input-group:focus-within .input-group:visited .input-group:active {
     @apply text-gray-900 border border-[#98a7c3] !outline-none !shadow !ring-0 !ring-transparent;
}
.search-input:focus, .search-input:hover, .search-input:active, .search-input:focus-visible, .search-input:focus-within {
    --tw-border-opacity: 1;
    --tw-shadow: 0 1px 2px 0px #1018280d, 0 0px 0px 4px #e0eaff !important;
    --tw-shadow-colored: 0 1px 2px 0px var(--tw-shadow-color), 0 0px 0px 4px var(--tw-shadow-color) !important;
    border-color: rgb(199 215 254 / var(--tw-border-opacity));
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    border-color: #98a7c3;
}
.sidebar-search-input .search-input:focus, .sidebar-search-input .search-input:hover, .sidebar-search-input .search-input:active, .sidebar-search-input .search-input:focus-visible, .sidebar-search-input .search-input:focus-within
{
    @apply text-gray-900 border border-[#98a7c3] !outline-none !shadow !ring-0 !ring-transparent;
}
.custom-hover-effect:hover, .custom-hover-effect:focus, .custom-hover-effect:focus-visible .custom-hover-effect:focus-within .custom-hover-effect:visited .custom-hover-effect:active {
    @apply border border-[#98a7c3] !outline-none !shadow !ring-0 !ring-transparent;
}

.input-group .input-group-prepend {
    @apply flex items-center  pl-[10px] pr-2 pointer-events-none font-normal;
}
.sidebar_Nav_Wrap .input-group.search-input
{
    @apply relative flex border border-gray-400 bg-white rounded-lg text-gray-900 font-medium;
}
.input-group.search-input .input-group-prepend {
    @apply flex items-center  pl-[7px] pr-[9px] pointer-events-none font-normal;
}
.input-group .input-group-prepend + .form_input_control {
    /* @apply pl-0; */
}
.input-group .input-group-append {
    @apply relative flex items-center px-3 border-l border-borderPrimary;
}
.date-picker-group .input-group-append {
    @apply absolute right-0 py-[6px] top-0 border-l-0;
}
.without_border_select_box .select_border {
    @apply border-0 border-gray-300 rounded-lg;
} 
.without_border_select_box .select_border:hover, .select_border:focus, .select_border:focus-visible .select_border:focus-within .select_border:visited .select_border:active {
    @apply  border-0 !outline-none  !ring-0 !ring-transparent !shadow-none;
}
.without_border_select_box .fwpo_select__placeholder{
    @apply text-secondaryTxtColor font-medium !text-base;
}
.without_border_select_box .select_border .fwpo_select__control {
    height: -moz-fit-content !important;
    height: fit-content !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    min-height: 24px !important;
    padding: 0px 0px 0px 0px !important;
    width: max-content !important;
  }
  .without_border_select_box .fwpo_select__value-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 4px !important;
}
.without_border_select_box .fwpo_select__control .fwpo_select__value-container {
    font-size: 16px !important;
    line-height: 1rem;
    line-height: 18px;
}
.without_border_select_box .fwpo_select__single-value {
    @apply text-secondaryTxtColor font-medium !text-base;
}
.without_border_select_box .fwpo_select__menu .fwpo_select__menu-list {
    min-width: 180px !important;
}
.select_border {
    @apply border border-gray-300 rounded-lg;
}
.select_border:hover, .select_border:focus, .select_border:focus-visible .select_border:focus-within .select_border:visited .select_border:active {
    @apply text-gray-900 border border-[#98a7c3] !outline-none !shadow !ring-0 !ring-transparent;
}
/* .table-searchInput .search-input {
    @apply shadow-sm focus:!shadow-[0_1px_2px_0px_rgba(16,24,40,0.05),0_0px_0px_4px_rgba(128,152,249,0.40)] border border-borderPrimary focus:border-[#c7d7fe] transition-all duration-[0.3s] outline-0;
} */
.input-group.is-invalid,
.is-invalid {
    @apply !border !border-dangerText rounded-lg;
}
.text-error {
    @apply text-dangerErrorText text-xs leading-5 font-normal mt-[6px];
}
.input-group.is-success,
.is-success {
    @apply border border-successBorder;
}
.app-page .form_input_control.form-control-md,
.app-page .form_input_control,
.app-page .form-control-md {
    @apply h-[34px];
}
.app-page .search-input .form-control-md
{
    @apply !text-[#333333] placeholder:text-gray-500 !text-xs !font-normal leading-[20px]; 
}
.app-page .sidebar-search-input .form-control-md
{
    @apply !text-[#333333] placeholder:text-gray-500 !text-xs !font-normal leading-[20px]; 
}
.app-page .fl-phone-field {
    @apply min-h-9 !important;
}
/* .app-page [type='search'] {
    @apply pl-10 !important;
    background: #fff url(../img/icons/search-lg.svg) no-repeat 10px center;
} */

/*-- select dropdown --*/
.fwpo_select__control {
    @apply min-h-[38px] h-[38px] bg-transparent cursor-pointer pr-2 text-gray-500 px-3 py-[5px] border-0 gap-0.5 pl-2 !important;
}
.app-page .fwpo_select__control {
    @apply !min-h-[34px] !h-[34px] !py-0;
}
.location-dropdown-custom .app-page .fwpo_select__control {
    @apply !min-h-[34px] !h-[fit-content] !py-0;
}
.fwpo_select__control .fwpo_select__indicator svg {
    color: #667085;
    width: 16px;
}
.fwpo_select__control .fwpo_select__value-container {
    @apply text-xs leading-[18px];
}
.fwpo_select__menu {
    @apply rounded-[10px] mt-[2px] !important;
}
.fwpo_select__menu .fwpo_select__menu-list {
    @apply rounded-[10px] border border-gray-200 p-1.5 !important;
}

.fwpo_select__menu .fwpo_select__option--is-selected:hover,
.fwpo_select__menu .fwpo_select__option--is-selected {
    @apply !bg-[#f9fafb] !text-primary;
}
.fwpo_select__menu .fwpo_select__option--is-selected svg {
    @apply  w-[12px] mr-[4px];
}
/* .fwpo_select__option.fwpo_select__option--is-focused, .fwpo_select__option.select__option--is-selected, .fwpo_select__option:hover {
    background-color: #f9fafb;
    border-radius: 6px;
    color: #444ce7;
} */
.fwpo_select__menu .fwpo_select__option
{
    color: #333333;
}
.fwpo_select__menu .fwpo_select__option:hover, .fwpo_select__menu .fwpo_select__option:active, .fwpo_select__menu .fwpo_select__option:focus, .fwpo_select__menu .fwpo_select__option:focus-visible, .fwpo_select__menu .fwpo_select__option:focus-within
{
  @apply  !bg-[#f9fafb] !text-primary;
}
.fwpo_select__menu .fwpo_select__menu-list .fwpo_select__option {
    @apply rounded-md text-[#333333];
}
/* .fwpo_select__menu .fwpo_select__menu-list .fwpo_select__option:not(:last-child) {
    @apply mb-[1px];
} */
.fwpo_select__placeholder {
    @apply text-[#667085] text-xs truncate font-medium;
}
.fwpo_select__single-value {
    @apply text-gray-600 text-xs font-medium leading-[18px] truncate;
}
.dropdown-detail
{
    display: flex;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  
}
.fwpo_select__menu .fwpo_select__menu-list .fwpo_select__option {
    @apply text-xs pl-1.5 pr-1 h-[36px];
}
.app-page .fwpo_select__menu .fwpo_select__menu-list .fwpo_select__option {
    @apply text-xs pl-1.5 pr-1 h-[36px];
}
/*-- switch --*/
.p-inputswitch {
    @apply w-9 h-5 relative;
}
.p-inputswitch .p-inputswitch-slider {
    @apply bg-gray-100;
}
.p-inputswitch .p-inputswitch-slider:before {
    @apply w-4 h-4 -mt-2 left-[2px];
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}
.p-inputswitch.p-highlight .p-inputswitch-slider {
    @apply bg-primary;
}
.p-inputswitch.p-highlight .p-inputswitch-slider:before {
    @apply translate-x-[calc(100%)];
}


.appearance-switch-btn .p-inputswitch {
    @apply w-[30px] h-[16px] relative;
}
.appearance-switch-btn .p-inputswitch .p-inputswitch-slider {
    @apply bg-gray-100;
}
.appearance-switch-btn .p-inputswitch .p-inputswitch-slider:before {
    @apply w-[14px] h-[14px] -mt-[7px] left-[1px];
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}
.appearance-switch-btn .p-inputswitch.p-highlight .p-inputswitch-slider {
    @apply bg-primary;
}
.appearance-switch-btn .p-inputswitch.p-highlight .p-inputswitch-slider:before {
    @apply translate-x-[calc(100%)];
}



/*-- TAB --*/
.fl-tab-btn-view {
    @apply bg-gray-50 border-borderSecondary  shadow-sm p-1 rounded-lg border cursor-pointer justify-start items-center gap-1 flex sm:w-auto w-full;
}
.fl-tab-btn-view .fl-tab-link {
    @apply w-full max-w-[250px] hover:bg-[#E0EAFF] hover:text-primary rounded-md text-xs font-semibold text-gray-700 px-3 py-1.5 transition-all ;
}
.fl-tab-btn-view .fl-tab-link.active {
    @apply bg-[#E0EAFF] text-primary;
}
/*-- TAB OVER  --*/

/*-- TAB2 --*/
.fl-tab-btn-view2 {
    @apply bg-[#FFFFFF]  border-borderGray  shadow-sm  rounded-lg border cursor-pointer justify-start items-center min-w-fit  flex  w-full;
}
.fl-tab-btn-view2 .fl-tab-link2 {
    @apply w-full h-[30px] first:rounded-l-lg last:rounded-r-lg border-r hover:bg-gray-50 border-borderGray text-xs last:border-r-0 font-semibold text-[#344054] px-3 py-1.5;
}
.fl-tab-btn-view2 .fl-tab-link2.active {
    @apply bg-gray-50 first:rounded-l-lg last:rounded-r-lg;
}

/*-- TAB3 --*/
.fl-tab-btn-view3 {
    @apply bg-gray-50 border-borderSecondary  shadow-sm p-1 rounded-lg border cursor-pointer justify-start items-center gap-1 flex sm:w-auto w-full;
}
.fl-tab-btn-view3 .fl-tab-link3 {
    @apply w-full max-w-[250px] hover:bg-gray-50 rounded-md text-xs font-semibold text-gray-700 px-3 py-1.5;
}
.fl-tab-btn-view3 .fl-tab-link3.active {
    @apply bg-white text-gray-700  shadow;
}
/*-- TAB4 --*/
.fl-tab-btn-view4 {
    @apply p-1 bg-gray-50 rounded-[10px] overflow-auto text-nowrap border border-borderSecondary cursor-pointer justify-start items-center gap-1 flex sm:w-auto w-full;
}
.fl-tab-btn-view4 .fl-tab-link4 {
    @apply sm:basis-auto basis-full px-3 hover:bg-white hover:text-[#101828] hover:font-semibold rounded-md  hover:shadow py-1.5 justify-center items-center gap-2  font-medium flex text-[#98a2b3] text-xs leading-tight;
}
.fl-tab-btn-view4 .fl-tab-link4.active {
    @apply bg-white rounded-md font-semibold  shadow text-[#101828];
}
/*-- TAB OVER  --*/
.divider {
    @apply bg-gray-200 m-0;
}

.time_select .fwpo_select__control {
    @apply border-0  !pl-[9px] w-[63px] !gap-0;
}

.time_select .fwpo_select__single-value {
    @apply mx-0;
}

.time_select .fwpo_select__control:hover {
    @apply border-none;
}

.time_select .fwpo_select__control .fwpo_select__value-container {
    @apply pr-[2px] !pl-0;
}

.time_select .fwpo_select__menu .fwpo_select__menu-list .fwpo_select__option .dropdown-detail {
    @apply pr-2;
}
.time_select .fwpo_select__menu {
    @apply !mt-[5px];
}

.time_select .fwpo_select__control .fwpo_select__value-container .fwpo_select__single-value {
    @apply text-gray-700;
}
.form_input_control_time_Select {
    @apply !border-0  !border-transparent !pr-0 !pl-3 !py-[9px] text-mainTextColor;
}

.profile_textarea textarea {
    @apply !border !border-gray-300 !shadow-InputAndButton !text-base !font-normal placeholder:text-base placeholder:font-normal placeholder:text-gray-500;
}

.login_service .fwpo_select__control {
    @apply !h-10 border border-gray-300 shadow-InputAndButton;
}

/*-- phone number dropdown -*/

.fl-phone-field.form_input_control {
    @apply !border border-borderPrimary !border-solid;
}
.fl-phone-field.form_input_control:hover, .fl-phone-field.form_input_control:focus, .fl-phone-field.form_input_control:focus-visible .fl-phone-field.form_input_control:focus-within .fl-phone-field.form_input_control:visited .fl-phone-field.form_input_control:active {
    @apply text-gray-900 border border-[#98a7c3] !outline-none !shadow !ring-0 !ring-transparent;
}
.country-code-dropdown-list {
    @apply px-1 pt-1 pb-1 text-xs h-44 overflow-auto rounded-3xl scrollbar-hide;
}
.country-code-dropdown-list > li {
    @apply px-2 py-2 cursor-pointer flex justify-between items-center text-mainTextColor rounded-md;
    transition: all 0.3s ease;
}
.country-code-dropdown-list > li:hover,
.country-code-dropdown-list > li.active {
    @apply bg-gray-50;
}

.app-page .custom-tip-checkbox-block .custom-tip-input {
    @apply !border !border-borderGray !text-gray-500;
}

.app-page .custom-tip-checkbox-block .custom-tip-input-value {
    @apply !border !border-primary !text-mainTextColor;
}

.opening-hours-row [type='text'] {
    @apply text-xs leading-[18px]  border-0 !important;
}

.opening-hours-row [type='text']::placeholder {
    @apply text-gray-500;
}

.opening-hours-row [type='text']:hover,
.opening-hours-row select:hover,
.opening-hours-row [type='text']:focus,
.opening-hours-row select:focus,
.opening-hours-row [type='text']:focus-visible,
.opening-hours-row select:focus-visible {
    @apply text-gray-900 outline-0 shadow-none ring-0 !important;
}

.p-dropdown-label span {
    @apply flex text-xs leading-[18px];
}

.p-dropdown-label {
    @apply flex items-center py-2 px-3;
}

.p-dropdown-header {
    @apply bg-white rounded-lg px-4;
}

.p-dropdown-header .p-dropdown-filter-container input {
    @apply py-[10px] px-[18px] w-full border-0 rounded-md  bg-inputBg text-sm focus:bottom-0 focus:drop-shadow-none max-h-9;
}

.p-dropdown-item.p-highlight,
.p-dropdown-item:hover,
.p-dropdown-item:focus {
    @apply bg-gray-50 rounded-lg;
}

.p-dropdown-item {
    @apply text-mainTextColor px-3 py-2;
}

.p-dropdown-items-wrapper {
    @apply p-1 rounded-lg;
}

.p-dropdown-item-label {
    @apply w-full;
}

.p-dropdown-item.p-highlight svg {
    @apply block;
}

.p-dropdown-item.p-highlight path {
    @apply stroke-blue-600 stroke-2;
}

.p-dropdown-empty-message {
    @apply text-sm;
}

.p-dropdown-trigger {
    @apply pl-[7px];
}

.p-dropdown-trigger svg {
    @apply h-[14px] w-[14px] stroke-2 text-gray-400;
}

.p-dropdown-trigger svg:hover {
    @apply text-gray-500;
}
.NoImgName
{
    @apply w-8 h-8 rounded-full mr-3 bg-gray-100 text-primary text-xs flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none;
}
.NoImgName img{
    @apply rounded-full;
}
.calendar-block .NoImgName
{
    @apply !rounded-[6px];
}
    
.calendar-block .NoImgName img{
    @apply !rounded-[6px];
}   

.p-datatable .p-datatable-thead > tr > th:first-child {
    padding-left: 20px;
}
.close-btn
{
    @apply h-[36px] w-[36px] min-w-[36px] flex justify-center border shadow-sm border-gray-300 hover:bg-gray-100 items-center rounded-[8px];
}