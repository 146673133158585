@font-face {
    font-family: "Plus Jakarta Sans", sans-serif;
    src: url('./PlusJakartaSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Plus Jakarta Sans", sans-serif;
    src: url('./PlusJakartaSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Plus Jakarta Sans", sans-serif;
    src: url('./PlusJakartaSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Plus Jakarta Sans", sans-serif;
    src: url('./PlusJakartaSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Plus Jakarta Sans", sans-serif;
    src: url('./PlusJakartaSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Plus Jakarta Sans", sans-serif;
    src: url('./PlusJakartaSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Plus Jakarta Sans", sans-serif;
    src: url('./PlusJakartaSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Plus Jakarta Sans", sans-serif;
    src: url('./PlusJakartaSans-ExtraBold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}