.fl-data-table {
    display: flex;
    flex-direction: column;

    .p-datatable-wrapper {
        flex: 1 1 0%;

        .p-datatable-table {
            // height:100%;
        }
    }

    .p-paginator-bottom {
        padding: 20px 16px;
    }
}

.p-datatable {
    font-size: 14px;

    .p-datatable-thead {
        background-color: #f9fafb;
        height: 44px;

        > tr {
            > th {
                font-size: 12px;
                text-align: left;
                font-weight: 500;
                color: #475467;
                background-color: transparent;
                border-bottom: 1px solid #eaecf0;
                padding: 8px 8px;

                &:first-child {
                    padding-left: 20px;
                }

                &:last-child {
                    padding-right: 20px;
                }

                &.p-sortable-column {
                    .p-column-header-content {
                        [data-pc-section='sort'] {
                            margin-left: 16px;
                        }
                    }
                }
            }
        }
    }

    .p-datatable-tbody {
        > tr {
            > td {
                padding: 12px 8px;

                &:first-child {
                    padding-left: 16px;
                }

                &:last-child {
                    padding-right: 16px;
                }
            }

            &.p-datatable-emptymessage {
                > td {
                    text-align: center;
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
            }
        }
    }

    .p-paginator {
        // font-family: 'SF Pro Text';

        .p-link {
            width: unset;
            min-width: unset;
            height: unset;
            padding: 0;
            color: #8c9cbb;
            background-color: transparent;
            font-size: 16px;
        }

        .p-paginator-pages {
            padding: 0 22px;

            .p-link {
                padding: 0 10px;

                &.p-highlight {
                    font-weight: bold;
                    color: #1f293c;
                }
            }
        }
    }

    .p-component-overlay {
        align-items: flex-start;
        background-color: transparent !important;
        padding-top: 46px;
    }
}

// .p-paginator-current {
//   float: left;
// }
